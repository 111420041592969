import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import styled, { css } from 'styled-components'

import { theme } from '@/styles/theme'

type ButtonProps = {
  children: ReactNode
  btnStyle: 'normal' | 'transparent' | 'outline' | 'square'
  className?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  isClicked?: boolean
  isForward?: boolean
  isBack?: boolean
} & ComponentPropsWithoutRef<'button'>

export const Button = ({
  children,
  btnStyle,
  className,
  type = 'button',
  onClick,
  isClicked = false,
  isForward = false,
  isBack = false,
  ...props
}: ButtonProps) => {
  switch (btnStyle) {
    case 'normal':
      return (
        <NormalButton type={type} onClick={onClick}>
          {children}
        </NormalButton>
      )
    case 'transparent':
      return (
        <TransparentButton className={className} type={type}>
          {children}
        </TransparentButton>
      )
    case 'outline':
      return (
        <OutlineButton
          className={className}
          type={type}
          onClick={onClick}
          disabled={isClicked}
          data-is-clicked={isClicked}
          data-forward={isForward}
          data-back={isBack}
          {...props}
        >
          {children}
        </OutlineButton>
      )
    case 'square':
      return (
        <SquareButton className={className} type={type} onClick={onClick}>
          {children}
          <Arrow />
        </SquareButton>
      )
    default:
      return <></>
  }
}

const ButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  transition: all 0.25s ease;
  cursor: pointer;
`

const NormalButton = styled.button`
  ${ButtonStyle}

  background-color: ${theme.colors.trialBlue};
  color: #fff;
  width: 230px;
  height: 47px;
  border: none;
  border-radius: 24px;
  font-size: 20px;

  &:hover {
    background-color: ${theme.colors.hoverLightBlue};
  }

  @media screen and (max-width: 1047px) {
    font-size: 18px;
  }
`

const TransparentButton = styled.button`
  ${ButtonStyle}

  background-color: transparent;
  color: ${theme.colors.trialBlue};
  border: 1px solid ${theme.colors.trialBlue};
  border-radius: 29px;
  width: 198px;
  height: 50px;
  font-size: 18px;

  &:hover {
    background-color: ${theme.colors.hoverLightBlue};
    color: #fff;
    border: ${theme.colors.hoverLightBlue};
  }
`

const OutlineButton = styled.button`
  ${ButtonStyle}

  background-color: ${theme.colors.trialBlue};
  color: #fff;
  border: 2px solid #fff;
  border-radius: 32px;
  width: 275px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.8px;

  &[data-forward='true'] {
    width: 200px;
    font-weight: 400;
    border-width: 1px;
    padding-left: 35px;

    &:hover {
      background-color: ${theme.colors.hoverLightBlue};
      border-color: ${theme.colors.hoverLightBlue};
    }
  }

  &[data-back='true'] {
    width: 200px;
    background-color: ${theme.colors.grey};
    font-weight: 400;
    border-width: 1px;
    padding-right: 35px;

    &:hover {
      background-color: ${theme.colors.hoverLightBlue};
      border-color: ${theme.colors.hoverLightBlue};
    }
  }

  &[data-is-clicked='true'] {
    background-color: ${theme.colors.grey};
    color: #fff;
    cursor: wait;
    &:hover {
      background-color: ${theme.colors.grey};
      color: #fff;
      border-width: 0;
    }
  }

  &:hover {
    background-color: ${theme.colors.hoverLightBlue};
    border-color: ${theme.colors.hoverLightBlue};
  }

  @media screen and (max-width: 1047px) {
    width: 262px;
    height: 46px;
    font-size: 16px;
  }
`

const SquareButton = styled.button`
  ${ButtonStyle}

  background-color: #3786C7;
  color: #fff;
  border: none;
  border-radius: 4px;
  min-width: 188px;
  height: 43px;
  font-size: 16px;
  padding: 8px 25px;

  &:hover {
    background-color: #3786c7b2;
  }
`

const Arrow = styled(MdArrowForwardIos)`
  margin-left: 8px;
`
