import Link from 'next/link'
import React, { useState, Fragment, useId } from 'react'
import { BiCopy } from 'react-icons/bi'
import { FiChevronRight } from 'react-icons/fi'
import styled, { css } from 'styled-components'

import { theme } from '@/styles/theme'
import { NAVIGATIONS } from '@/utils/routes'

import { DrawerNaviItem } from './DrawerNaviItem'
import { Button } from '@/components/elements/Button'
import { Line } from '@/components/elements/Line'

export const Header = () => {
  const [isNaviOpen, setIsNaviOpen] = useState<boolean>(false)
  const [spNaviClicked, setSpNaviClicked] = useState<number>(-1)
  const [pcNaviFocused, setPcNaviFocused] = useState<number>(-1)
  const id = useId()

  const toggleMenu = () => {
    setIsNaviOpen(!isNaviOpen)
    setSpNaviClicked(-1)
  }

  const toggleItem = (index: number) => {
    if (spNaviClicked === index) {
      return setSpNaviClicked(-1)
    }

    setSpNaviClicked(index)
  }

  const handleOverlayClick = () => {
    setIsNaviOpen(false)
  }

  const drawerItem = (index: number) => {
    setPcNaviFocused(index)
  }

  const unDrawerItem = () => {
    setPcNaviFocused(-1)
  }

  return (
    <>
      <Wrapper>
        <Link href="/" passHref tabIndex={0}>
          <Logo>
            <img src="/images/logo.svg" alt="ロゴ" />
          </Logo>
        </Link>
        <PcNavi>
          <LineStyled color="grey" weight={1} direction="vertical" />
          {NAVIGATIONS.map(({ path, name, subdirectories }, index) => (
            <Fragment key={name}>
              <LinkWrapper
                tabIndex={0}
                onFocus={() => drawerItem(index)}
                onMouseOver={() => drawerItem(index)}
                onBlur={() => unDrawerItem()}
                onMouseLeave={() => unDrawerItem()}
              >
                <Link href={path} passHref>
                  <a tabIndex={-1}>{name}</a>
                </Link>
                {subdirectories.length > 0 && (
                  <Drawer className={pcNaviFocused === index ? 'focused' : ''}>
                    <DrawerInner
                      className={pcNaviFocused === index ? 'focused' : ''}
                    >
                      <DrawerHead>
                        <Link href={path} passHref>
                          <a>
                            <p>{name}</p>
                            <DrawerBtn btnStyle="square">
                              {name}トップ
                            </DrawerBtn>
                          </a>
                        </Link>
                      </DrawerHead>
                      <DrawerNavi>
                        {subdirectories.map(
                          ({ path, name, imagePath, isExternalLink }) => (
                            <DrawerNaviItem
                              key={path}
                              link={path}
                              imgPath={imagePath}
                              title={name}
                              isExternalLink={isExternalLink}
                            />
                          )
                        )}
                      </DrawerNavi>
                    </DrawerInner>
                  </Drawer>
                )}
              </LinkWrapper>
              <LineStyled color="grey" weight={1} direction="vertical" />
            </Fragment>
          ))}
        </PcNavi>
        <Language>
          <Globalicon>
            <img src="/images/global_icon.svg" alt="グローバルアイコン" />
          </Globalicon>           
          <Japaniese>
            <span>JA</span>
          </Japaniese>
          <Vector>
            <img src="/images/vector.svg" alt="ベクター" />
          </Vector>
          <Link href="/en" passHref >  
            <English>
              <span>EN</span>
            </English>
          </Link>
        </Language>
        <HamburgerMenu
          aria-expanded={isNaviOpen ? 'true' : 'false'}
          onClick={() => toggleMenu()}
          aria-label="メニュー"
          aria-controls={id}
        >
          <span />
          <span />
          <span />
        </HamburgerMenu>
      </Wrapper>
      <SpNavi id={id} aria-hidden={!isNaviOpen}>
        <ul>
          {NAVIGATIONS.map(({ name, subdirectories, path }, index) => (
            <NaviItem
              key={name}
              aria-expanded={
                isNaviOpen && spNaviClicked === index ? 'true' : 'false'
              }
              aria-controls={`${name}のメニュー`}
            >
              <NaviParent onClick={() => toggleItem(index)}>
                <span>{name}</span>
                <Icon>
                  <span />
                  <span />
                </Icon>
              </NaviParent>
              {subdirectories && (
                <NaviChildren id={`${name}のメニュー`}>
                  <li onClick={() => toggleMenu()}>
                    <Link href={path} passHref>
                      <a>
                        <FiChevronRight
                          color={theme.colors.trialBlue}
                          size={22}
                        />
                        {name}トップページ
                      </a>
                    </Link>
                  </li>
                  {subdirectories?.map(({ path, name, isExternalLink }) => (
                    <li key={name} onClick={() => toggleMenu()}>
                      {isExternalLink ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={path}
                        >
                          <FiChevronRight
                            color={theme.colors.trialBlue}
                            size={22}
                          />
                          {name}
                          <ExLinkIcon size="1em" />
                        </a>
                      ) : (
                        <Link href={path} passHref>
                          <a>
                            <FiChevronRight
                              color={theme.colors.trialBlue}
                              size={22}
                            />
                            {name}
                          </a>
                        </Link>
                      )}
                    </li>
                  ))}
                </NaviChildren>
              )}
            </NaviItem>
          ))}
        </ul>
      </SpNavi>
      <Overlay
        aria-hidden={!isNaviOpen}
        onClick={handleOverlayClick}
        role="button"
        tabIndex={-1}
        aria-label="メニューを閉じる"
      />
    </>
  )
}

const Wrapper = styled.header`
  height: 105px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 0 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  @media screen and (max-width: 1047px) {
    height: 53px;
    padding: 0 16px;
  }
`

const Logo = styled.a`
  position: relative;
  display: block;
  width: 300px;
  height: 27px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1047px) {
    width: 180px;
    height: 16px;
  }
`

const PcNavi = styled.nav`
    display: flex;
    height: 100%;
    margin-left: auto; 
    margin-right: 20px;
    padding: 0px 20px;


  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const HamburgerLine = css`
  position: absolute;
  width: 26px;
  height: 2px;
  background-color: ${theme.colors.textTitle};
  transition: all 0.5s;
  left: 0;
`

const HamburgerMenu = styled.button`
  display: none;
  position: relative;
  width: 25px;
  height: 23px;

  border: 0;
  background-color: transparent;

  span {
    &:nth-child(1) {
      ${HamburgerLine};
      top: 0;
    }

    &:nth-child(2) {
      ${HamburgerLine};
      top: 10px;
    }

    &:nth-child(3) {
      ${HamburgerLine};
      top: 20px;
    }
  }

  &[aria-expanded='true'] span {
    &:nth-child(1) {
      transform: translateY(0.7rem) rotate(-45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: translateY(-0.5rem) rotate(45deg);
    }
  }

  @media screen and (max-width: 1047px) {
    display: block;
  }
`

const SpNavi = styled.nav`
  position: fixed;
  top: 53px;
  width: 100%;
  background-color: #fff;
  transform: translateY(calc(-100vh - 53px));
  transition: all 1s ease-in-out;
  z-index: -1;

  &[aria-hidden='false'] {
    z-index: 99;
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
  }

  &[aria-hidden='true'] > * {
    display: none;
  }
`

const NaviChildren = styled.ul`
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.25s ease;
  visibility: hidden;

  li {
    height: 53px;
    padding: 0 37px;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.backgroundBlue};

    :hover {
      text-decoration: underline;
      color: ${theme.colors.hoverLightBlue};
    }
  }

  svg {
    margin-right: 6px;
  }

  li:nth-child(1) {
    border-bottom: 1px solid ${theme.colors.backgroundBlue};

    a {
      font-size: 16px;
      border-bottom: none;
    }
  }
`

const NaviParent = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 71px;
  padding: 0 19px;
  border: none;
  background: #ffffff;

  span {
    font-size: 16px;
    font-weight: bold;
  }
`

const Icon = styled.span`
  position: relative;
  background: transparent;
  border: 0;
  width: 15px;
  height: 15px;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    display: block;
    width: 100%;
    height: 2px;
    background-color: #b5bdc4;
    transition: all 0.5s;

    &:nth-child(2) {
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
    }
  }
`

const NaviItem = styled.li`
  border-top: 1px solid ${theme.colors.backgroundBlue};

  &[aria-expanded='true'] {
    background-color: rgba(225, 232, 239, 0.3);

    ${NaviChildren} {
      max-height: 100dvh;
      max-height: 100vh;
      opacity: 1;
      padding-bottom: 36px;
      visibility: visible;
    }

    ${NaviParent} {
      border-bottom: 1px solid ${theme.colors.backgroundBlue};

      span {
        color: ${theme.colors.trialBlue};
      }
    }

    ${Icon} span {
      &:nth-child(1) {
        opacity: 0;
      }

      &:nth-child(2) {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
        background-color: ${theme.colors.trialBlue};
      }
    }
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 53px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: none;
  background-color: #3a3c3e;
  opacity: 0.6;

  &[aria-hidden='false'] {
    display: block;
  }
`

const Drawer = styled.div`
  position: absolute;
  max-height: 0;
  top: 105px;
  left: 0;
  width: 100vw;
  background-color: ${theme.colors.trialBlue};
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  visibility: hidden;

  &.focused {
    max-height: 596px;
    transition: all 0.3s ease-in-out;
    visibility: visible;
  }
`

const DrawerInner = styled.div`
  width: 1040px;
  max-height: 0;
  margin: 0 auto;
  display: flex;
  column-gap: 40px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  visibility: hidden;

  &.focused {
    padding: 50px 0;
    max-height: 596px;
    transition: all 0.3s ease-in-out;
    visibility: visible;
  }
`

const DrawerHead = styled.div`
  flex-shrink: 0;

  p {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 18px;
  }
`

const DrawerBtn = styled(Button)`
  white-space: nowrap;
`

const DrawerNavi = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px 40px;
`

const LineStyled = styled(Line)`
  margin: 44.5px 0;
  height: 16px;
`

const LinkWrapper = styled.div`
  padding: 44.5px 24px;
  font-weight: bold;
  display: flex;
  align-items: center;

  > a {
    display: flex;
    align-items: center;
    height: 105px;
  }

  &:hover {
    cursor: pointer;

    & > a {
      color: ${theme.colors.trialBlue};
    }
  }
`

const ExLinkIcon = styled(BiCopy)`
  margin-left: 6px;
`

const Language = styled.div`
  display: flex
  align-items: center;
  @media screen and (max-width: 1047px) {
    margin-left: auto;
    margin-right: 10px;
  }

  &:hover {
    color: #4D84C2; 
 }
`

const Globalicon = styled.a`
  width: 14px;
  height: 13px;
  margin-right: 7px;
`

const Japaniese = styled.a`
  text-align: center;
  font-family: Noto Sans JP;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; 
  text-decoration-line: underline;
  margin-right: 4px
`
const Vector = styled.a`
  width: 10px;
  height: 12px;
  margin-right: 10px;
  stroke-width: 1px;
  stroke: var(--black, #3A3C3E);
  margin-right: 4px
`
const English = styled.span`
  cursor: pointer;
  text-align: center;
  font-family: Noto Sans JP;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 15px */
  margin-right: 4px;
`