import React from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

type LineProps = {
  color: 'trialBlue' | 'grey' | 'greyBlue' | 'white'
  weight: 1 | 4
  direction: 'vertical' | 'horizon'
  className?: string
}

export const Line = ({ className, ...props }: LineProps) => {
  return <LineStyle className={className} {...props} />
}

const LineStyle = styled.div<LineProps>`
  background-color: ${({ color }) =>
    color === 'trialBlue'
      ? theme.colors.trialBlue
      : color === 'grey'
      ? '#c0c0c0'
      : color === 'greyBlue'
      ? theme.colors.backgroundBlue
      : '#ffffff'};
  ${({ direction, weight }) =>
    direction === 'horizon' ? `height: ${weight}px` : `width: ${weight}px`};
`
