import React from 'react'
import styled from 'styled-components'

type SpacerProps = {
  w?: { sp: number; pc: number }
  h?: { sp: number; pc: number }
}

export const Spacer = ({ ...props }: SpacerProps) => {
  return <SpacerStyle {...props} />
}

const SpacerStyle = styled.div<SpacerProps>(({ w, h }) => [
  w &&
    `
    width: ${w.pc}px;
    @media (max-width: 1047px) {
      width: ${w.sp}px;
    }
  `,
  h &&
    `
    height: ${h.pc}px;
    @media (max-width: 1047px) {
      height: ${h.sp}px;
    }
  `,
])
