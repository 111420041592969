import Link from 'next/link'
import styled from 'styled-components'

import { theme } from '@/styles/theme'
import { NAVIGATIONS } from '@/utils/routes'

import { Spacer } from '@/components/elements/Spacer'

export const FooterTop = () => {
  return (
    <>
      <FooterTopContainer>
        <Spacer h={{ sp: 0, pc: 70 }} />
        <FooterTopNav>
          {NAVIGATIONS.map(({ path, name, subdirectories }, index, array) => (
            <FooterNavInner key={name}>
              <Link href={path} passHref>
                <FooterNaviTitle>{name}</FooterNaviTitle>
              </Link>
              {index < array.length - 1 && <Border />}
              {subdirectories && (
                <>
                  <Spacer h={{ sp: 0, pc: 10 }} />
                  <FooterNaviLinks>
                    {subdirectories.map(({ name, path, isExternalLink }) => (
                      <FooterNaviLinksItem key={name}>
                        {isExternalLink ? (
                          <a
                            key={name}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={path}
                            className="external-link"
                          >
                            {name}
                          </a>
                        ) : (
                          <Link key={name} href={path} passHref>
                            <a>{name}</a>
                          </Link>
                        )}
                      </FooterNaviLinksItem>
                    ))}
                  </FooterNaviLinks>
                </>
              )}
            </FooterNavInner>
          ))}
        </FooterTopNav>
        <Spacer h={{ sp: 0, pc: 70 }} />
      </FooterTopContainer>
    </>
  )
}

const FooterTopContainer = styled.div`
  background-color: ${theme.colors.backgroundGrey};
`

const FooterTopNav = styled.nav`
  width: 1040px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1047px) {
    width: 100%;
    flex-direction: column;
    padding: 0 20px;
  }
`

const FooterNavInner = styled.div`
  font-size: 14px;
`

const Border = styled.div`
  @media screen and (max-width: 1047px) {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    border-bottom: 1px solid ${theme.colors.backgroundBlue};
  }
`

const FooterNaviTitle = styled.a`
  display: block;
  font-weight: bold;
  line-height: 20px;
  transition: all 0.25s ease;

  &:hover {
    text-decoration: underline;
    color: ${theme.colors.hoverLightBlue};
  }

  @media screen and (max-width: 1047px) {
    font-size: 15px;
    line-height: 21px;
    padding: 18px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      background: url('/images/angle-right.svg') no-repeat;
      width: 12px;
      height: 17px;
    }
  }
`

const FooterNaviLinks = styled.ul`
  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const FooterNaviLinksItem = styled.li`
  transition: all 0.25s ease;

  a {
    display: block;
    line-height: 32px;
  }

  &:hover {
    a {
      text-decoration: underline;
      color: ${theme.colors.hoverLightBlue};
    }

    .external-link:after {
      background: url('/images/external-link-hoverblue.svg') no-repeat;
    }
  }

  .external-link {
    &:after {
      content: '';
      background: url('/images/external-link-black.svg') no-repeat;
      width: 12px;
      height: 12px;
      margin-left: 8px;
      display: inline-block;
    }
  }
`
