import { ReactElement } from 'react'
import styled from 'styled-components'

import { PageBackground } from '@/components/elements/PageBackground'
import { PageTitle } from '@/components/elements/PageTitle'
import { Spacer } from '@/components/elements/Spacer'
import { TopPageTitle } from '@/components/elements/TopPageTitle'
import { BreadCrumbs } from '@/components/layouts/BreadCrumbs'
import { FooterArea as Footer } from '@/components/layouts/Footer'
import { Header } from '@/components/layouts/Header'

type LayoutProps = {
  title: string
  subTitle: string
  imgUrl: string
  breadCrumbs: {
    name: string
    path?: string
  }[]
  children: ReactElement
  isRequiredBackground?: boolean
}

export const Layout = ({
  children,
  isRequiredBackground,
}: Pick<LayoutProps, 'children' | 'isRequiredBackground'>) => (
  <>
    <Header />
    {isRequiredBackground ? (
      <PageBackground>
        <Main>{children}</Main>
      </PageBackground>
    ) : (
      <Main>{children}</Main>
    )}

    <Footer />
  </>
)

export const TitleLayout = ({
  title,
  subTitle,
  breadCrumbs,
  children,
  isRequiredBackground,
}: Omit<LayoutProps, 'imgUrl'>) => (
  <>
    <Header />
    <Main>
      <PageTitle pageTitle={title} subPageTitle={subTitle} />
      <Wrapper>
        <Spacer h={{ sp: 10, pc: 14 }} />
        <BreadCrumbs breadCrumbs={breadCrumbs} />
      </Wrapper>
      {isRequiredBackground ? (
        <PageBackground>{children}</PageBackground>
      ) : (
        <>{children}</>
      )}
    </Main>
    <Footer />
  </>
)

export const ImageTitleLayout = ({
  title,
  subTitle,
  imgUrl,
  breadCrumbs,
  children,
  isRequiredBackground,
}: LayoutProps) => (
  <>
    <Header />
    <Main>
      <TopPageTitle titleText={title} subtitleText={subTitle} imgUrl={imgUrl} />
      <Spacer h={{ sp: 10, pc: 14 }} />
      <Wrapper>
        <Spacer h={{ sp: 10, pc: 14 }} />
        <BreadCrumbs breadCrumbs={breadCrumbs} />
      </Wrapper>
      {isRequiredBackground ? (
        <PageBackground>{children}</PageBackground>
      ) : (
        <>{children}</>
      )}
    </Main>
    <Footer />
  </>
)

const Wrapper = styled.div`
  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const Main = styled.main`
  padding-top: 105px;

  @media screen and (max-width: 1047px) {
    padding-top: 53px;
  }
`
