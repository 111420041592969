import styled from 'styled-components'

import { FooterBottom } from './FooterBottom'
import { FooterTop } from './FooterTop'

export const FooterArea = () => {
  return (
    <Footer>
      <FooterTop />
      <FooterBottom />
    </Footer>
  )
}

const Footer = styled.footer``
