import styled from 'styled-components'

import { theme } from '@/styles/theme'
import { PxToVw } from '@/utils/convertPx'

import { Button } from '@/components/elements/Button'

type Props = {
  title?: string
  imgPath: string
  className?: string
}

export const LinkCard = ({ title, imgPath, className }: Props) => {
  return (
    <ItemLink className={className}>
      <BgImage>
        <img src={imgPath} alt={title} />
      </BgImage>
    </ItemLink>
  )
}

const LinkButton = styled(Button)`
  position: absolute;
  bottom: 17px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 1047px) {
    max-width: 262px;
    width: ${PxToVw(262, 390)};
  }
`

const BgImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: opacity, background-color 0.3s;
  background-color: ${theme.colors.backgroundPaleBlue};
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ItemLink = styled.div`
  display: block;
  border-radius: 8px;
  background-color: ${theme.colors.backgroundPaleBlue};
  width: 316px;
  height: 188px;
  position: relative;
  transition: all 0.25s ease;

  &:hover ${BgImage} {
    opacity: 0.6;
    background-color: #e5f5fc66;
  }

  &:hover ${LinkButton} {
    border-color: #fff;
    background-color: ${theme.colors.hoverLightBlue};
    color: #ffffff;
  }

  @media screen and (max-width: 1047px) {
    max-width: 350px;
    width: ${PxToVw(350, 390)};
    height: 208px;
  }
`
