import styled from 'styled-components'

import { theme } from '@/styles/theme'

type PageTitleProps = {
  pageTitle: string
  subPageTitle: string
}

export const PageTitle = ({ pageTitle, subPageTitle }: PageTitleProps) => {
  return (
    <PageTitelDivColor>
      <PageTitelDiv>
        <PageTitleText>
          {pageTitle}
          <PageSubTitleText>{subPageTitle}</PageSubTitleText>
        </PageTitleText>
      </PageTitelDiv>
    </PageTitelDivColor>
  )
}

const PageTitelDivColor = styled.div`
  background-color: ${theme.colors.trialBlue};
`

const PageTitelDiv = styled.div`
  width: 1040px;
  display: flex;
  align-items: center;
  height: 76px;
  margin: 0 auto;
  padding: 22px 0 18px;

  @media screen and (max-width: 1047px) {
    width: 100%;
    padding: 12px 20px 9px;
    height: 38px;
  }
`

const PageTitleText = styled.h1`
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  color: #f4f4f4;
  font-family: ${theme.fontEn};

  @media screen and (max-width: 1047px) {
    font-size: 17px;
    line-height: 1;
  }
`

const PageSubTitleText = styled.span`
  font-size: 20px;
  line-height: 29px;
  font-weight: normal;
  color: #ffffff;
  margin-left: 21px;

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    line-height: 1;
    margin-left: 14px;
  }
`
