import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

type PageBackgroundProps = {
  children: ReactNode
}

export const PageBackground = ({ children }: PageBackgroundProps) => {
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    if (typeof window !== 'undefined')
      setHeight(window.document.body.offsetHeight)
  }, [])

  return (
    <Wrapper>
      {children}
      <PageBackgroundStyle scrollHeight={height} />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;
`

const PageBackgroundStyle = styled.div<{ scrollHeight: number }>`
  position: absolute;
  bottom: 0;
  z-index: -1;
  background-color: ${theme.colors.backgroundBlue};

  width: 100%;
  height: ${({ scrollHeight }) => scrollHeight / 3}px;

  clip-path: polygon(0 32%, 100% 0, 100% 100%, 0% 100%);

  @media screen and (max-width: 1047px) {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  }
`
