import Link from 'next/link'
import React, { Fragment } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

type BreadCrumbsProps = {
  breadCrumbs: {
    name: string
    path?: string
  }[]
}

export const BreadCrumbs = (props: BreadCrumbsProps) => {
  return (
    <BreadCrumbsStyle>
      <Wrapper>
        {props.breadCrumbs.map(({ name, path }) => (
          <Fragment key={name}>
            <Item>
              {path ? (
                <Link href={path} passHref>
                  <BreadCrumbsLink>{name}</BreadCrumbsLink>
                </Link>
              ) : (
                <BreadCrumbsLink as="p">{name}</BreadCrumbsLink>
              )}
              <Arrow />
            </Item>
          </Fragment>
        ))}
      </Wrapper>
    </BreadCrumbsStyle>
  )
}

const BreadCrumbsStyle = styled.div`
  width: 1040px;
  margin: 0 auto;
  overflow: hidden;

  @media screen and (max-width: 1047px) {
    width: 100%;
  }
`

const Wrapper = styled.ul`
  display: flex;
  align-items: center;
`

const Arrow = styled(FiChevronRight)`
  font-size: 20px;
  margin: 0 8px;
  color: #707070;

  @media screen and (max-width: 1047px) {
    margin: 0 4px;
    font-size: 17px;
  }
`

const Item = styled.li`
  display: flex;
  align-items: center;

  &:last-child {
    overflow: hidden;
    & ${Arrow} {
      display: none;
    }
  }
`

const BreadCrumbsLink = styled.a<{ as?: keyof JSX.IntrinsicElements }>`
  display: block;
  opacity: ${({ as }) => (as ? '' : '0.5')};
  color: ${theme.colors.textTitle};
  font-size: 14px;
  transition: all 0.25s ease;
  overflow: ${({ as }) => (as ? 'hidden' : 'visible')};
  text-overflow: ellipsis;
  white-space: pre;
  padding:3px;

  &:hover {
    ${({ as }) =>
      as
        ? ``
        : `text-decoration: underline;
    color: ${theme.colors.hoverLightBlue};opacity:1;`}
  }

  @media screen and (max-width: 1047px) {
    font-size: 12px;
    line-height: 1;
  }
`
