type Subdirectory = {
  path: string
  name: string
  detail?: string
  imagePath?: string
  isExternalLink: boolean
  subdirectories?: readonly Subdirectory[]
}

export type Route = {
  path: string
  name: string
  subdirectories: readonly Subdirectory[]
}

export const COMPANY_ROUTE = {
  path: '/company/',
  name: '企業情報',
  subdirectories: [
    {
      path: '/company/topmessage/',
      name: 'トップメッセージ',
      imagePath: '/images/company-top-message-link.svg',
      isExternalLink: false,
    },
    {
      path: '/company/philosophy/',
      name: '経営理念',
      imagePath: '/images/company-philosophy-link.svg',
      isExternalLink: false,
    },
    {
      path: '/company/overview/',
      name: '会社概要',
      imagePath: '/images/company-overview-link.svg',
      isExternalLink: false,
    },
    {
      path: '/company/officer/',
      name: '役員一覧',
      imagePath: '/images/drawer-officer-links.svg',
      isExternalLink: false,
    },
    {
      path: '/company/history/',
      name: '会社沿革',
      imagePath: '/images/company-history-link.svg',
      isExternalLink: false,
    },
    {
      path: '/company/access/',
      name: 'アクセス',
      imagePath: '/images/company-access-link.svg',
      isExternalLink: false,
    },
  ],
} as const

const BUSINESS_ROUTE = {
  path: '/business/',
  name: '事業内容',
  subdirectories: [
    {
      path: '/business/#retail',
      name: '流通小売事業',
      imagePath: '/images/business-retail-links.svg',
      isExternalLink: false,
    },
    {
      path: '/business/#retail-ai',
      name: 'リテールAI事業',
      imagePath: '/images/business-retail-ai-links.svg',
      isExternalLink: false,
    },
    {
      path: '/business/#others',
      name: 'その他',
      imagePath: '/images/business-other-links.svg',
      isExternalLink: false,
    },
  ],
} as const

const NEWS_ROUTE = {
  path: '/news/',
  name: 'ニュースルーム',
  subdirectories: [
    {
      path: '/news/release/',
      name: 'ニュースリリース',
      imagePath: '/images/news-release.svg',
      isExternalLink: false,
    },
    {
      path: '/news/blog/',
      name: '広報ブログ',
      imagePath: '/images/news-blog.svg',
      isExternalLink: false,
    },
    {
      path: '/news/media/',
      name: 'メディアキット',
      imagePath: '/images/news-media-kit.svg',
      isExternalLink: false,
    },
    {
      path: '/news/video/',
      name: 'ビデオライブラリー',
      imagePath: '/images/news-video-library.svg',
      isExternalLink: false,
    },
    {
      path: '/news/exposure/',
      name: 'メディア露出一覧',
      imagePath: '/images/news-media-exposure.svg',
      isExternalLink: false,
    },
    {
      path: '/news/machidukuri/',
      name: '街づくりプロジェクト',
      imagePath: '/images/news-machidukuri.svg',
      isExternalLink: false,
    },
    {
      path: '/remoteworktown/',
      name: 'リモートワークタウン',
      imagePath: '/images/news-remoteworktown.svg',
      isExternalLink: false,
    }
  ],
} as const

export const IR_TOP_ROUTE = {
  path: '/ir/',
  name: '株主・投資家向け情報',
  subdirectories: [
    {
      name: 'トップメッセージ',
      path: '/company/topmessage/',
      imagePath: '/images/company-top-message-link.svg',
      isExternalLink: false,
    },
    {
      name: '',
      path: '/ir/library/financial-announcement/',
      imagePath: '/images/serch_graph.svg',
      isExternalLink: false,
    },
    {
      name: '個人投資家の皆様へ',
      path: '/ir/investor',
      imagePath: '/images/bussiness-man-and-woman.svg',
      isExternalLink: false,
    },
    {
      name: 'IRニュース',
      path: '/ir/news/',
      imagePath: '',
      isExternalLink: false,
    },
    {
      name: '株価情報',
      path: '/ir/stock/price/',
      imagePath: '',
      isExternalLink: false,
    },
    {
      name: '月次売上高速報',
      path: '/ir/financial/monthly-sales/',
      imagePath: '',
      isExternalLink: false,
    },
    {
      name: 'IRカレンダー',
      path: '/ir/calendar/',
      imagePath: '',
      isExternalLink: false,
    },
  ],
} as const

export const IR_MANAGE_ROUTE = {
  path: '/ir/manage/',
  name: '経営方針',
  imagePath: '/images/globalnavi-ir-manage.svg',
  isExternalLink: false,
  subdirectories: [
    {
      name: 'トップメッセージ',
      path: '/company/topmessage/',
      imagePath: '/images/company-top-message-link.svg',
      detail:
        '「リテールDXを通じて世の中を豊かな社会へ」代表取締役会長のメッセージを掲載しております。',
      isExternalLink: false,
    },
    {
      name: '経営理念',
      path: '/company/philosophy/',
      imagePath: '/images/company-philosophy-link.svg',
      detail:
        '当社グループのパーパス、ビジョン、バリューについて掲載しております。',
      isExternalLink: false,
    },
    {
      name: '事業計画及び成長可能性',
      path: '/ir/library/projection/',
      imagePath: '/images/projection-link.svg',
      detail:
        '当社グループのビジネスモデルや、中長期的な成長を実現するための戦略、施策などについて掲載しております。',
      isExternalLink: false,
    },
    {
      name: '対処すべき課題',
      path: '/ir/manage/issue/',
      imagePath: '/images/issue-link.svg',
      detail: '優先的に対処すべき事業上の課題について掲載しております。',
      isExternalLink: false,
    },
    {
      name: '事業等のリスク',
      path: '/ir/manage/risk/',
      imagePath: '/images/risk-link.svg',
      detail: '事業領域ごとに想定されるリスクについて掲載しております。',
      isExternalLink: false,
    },
    {
      name: 'ディスクロージャー・IRポリシー',
      path: '/ir/manage/disclosure/',
      imagePath: '/images/disclosure-link.svg',
      detail: 'IR活動における基本方針について掲載しております。',
      isExternalLink: false,
    },
  ],
} as const

export const IR_GOVERNANCE_ROUTE = {
  path: '/ir/governance/',
  name: 'コーポレート・ガバナンス',
  isExternalLink: false,
  imagePath: '/images/globalnavi-ir-govanance.svg',
  subdirectories: [
    {
      name: 'コーポレート・ガバナンスに関する基本的な考え方',
      path: '/ir/governance/basics/',
      imagePath: '/images/ir-governance-basics.webp',
      detail:
        'コーポレート・ガバナンスに関する基本的な考え方について掲載しております。',
      isExternalLink: false,
    },
    {
      name: 'コーポレート・ガバナンス体制',
      path: '/ir/governance/organization/',
      imagePath: '/images/ir-governance-organization.webp',
      detail:
        '企業統治体制の概要と当該体制を採用する理由について掲載しております。',
      isExternalLink: false,
    },

    {
      name: '役員一覧',
      path: '/company/officer/',
      imagePath: '/images/officer-link.svg',
      detail: '当社の役員情報について掲載しております。',
      isExternalLink: false,
    },
    {
      name: 'ステークホルダーに関わる施策',
      path: '/ir/governance/policy/',
      imagePath: '/images/ir-governance-policy.webp',
      detail:
        'ステークホルダーの立場の尊重に係る取組み状況について掲載しております。',
      isExternalLink: false,
    },
    {
      name: '内部統制システムに関する基本方針',
      path: '/ir/governance/internal-control/',
      imagePath: '/images/ir-governance-internal-control.webp',
      detail:
        '内部統制システム及びリスク管理体制の整備の状況について掲載しております。',
      isExternalLink: false,
    },
    {
      name: '反社会的勢力への対応',
      path: '/ir/governance/antisocial/',
      imagePath: '/images/ir-governance-antisocial.webp',
      detail:
        '反社会的勢力排除に向けた基本的な考え方及びその整備状況について掲載しております。',
      isExternalLink: false,
    },
  ],
} as const

export const IR_FINANCIAL_ROUTE = {
  path: '/ir/financial/',
  name: '業績・財務情報',
  imagePath: '/images/globalnavi-ir-financial.svg',
  isExternalLink: false,
  subdirectories: [
    {
      name: '月次売上高速報',
      path: '/ir/financial/monthly-sales/',
      imagePath: '/images/ir-financial-monthly.webp',
      detail:
        '当社グループ営業店舗における月次売上高速報について掲載しております。',
      isExternalLink: false,
    },
    {
      name: '決算レビュー',
      path: '/ir/financial/review/',
      imagePath: '/images/ir-financial-review.webp',
      detail: '四半期ごとの決算概要について掲載しております。',
      isExternalLink: false,
    },
    // pathをhttps;でリロードする
    {
      name: '連結財務諸表',
      path: '/ir/financial/cfs/',
      imagePath: '/images/ir-financial-cfs.webp',
      detail: '当社グループの連結PL、BS、CFについて掲載しております。',
      isExternalLink: false,
    },
    // pathをhttps;でリロードする
    {
      name: '連結業績予想',
      path: '/ir/financial/forecast/',
      imagePath: '/images/ir-financial-forecast.webp',
      detail: '当社グループの連結業績予想について掲載しております。',
      isExternalLink: false,
    },
  ],
} as const

export const IR_LIBRARY_ROUTE = {
  path: '/ir/library/',
  name: 'IRライブラリー',
  imagePath: '/images/globalnavi-ir-library.svg',
  isExternalLink: false,
  subdirectories: [
    {
      name: '決算関連資料',
      path: '/ir/library/financial-announcement/',
      imagePath: '/images/irlibrary-financial-announcement-card.svg',
      detail: '決算短信及び決算説明資料について掲載しております。',
      isExternalLink: false,
    },
    {
      name: '有価証券報告書',
      imagePath: '/images/irlibrary-securities-card.svg',
      detail: '四半期報告書及び有価証券報告書について掲載しております。',
      path: '/ir/library/securities/',
      isExternalLink: false,
    },
    {
      name: '事業計画及び成長可能性',
      path: '/ir/library/projection/',
      imagePath: '/images/irlibrary-projection-card.svg',
      detail:
        '当社グループのビジネスモデルや、中長期的な成長を実現するための戦略、施策などについて掲載しております。',
      isExternalLink: false,
    },
    {
      name: '株主通信',
      path: '/ir/library/newsletter/',
      imagePath: '/images/news-blog.svg',
      detail:
        '環境配慮の観点から、株主通信はWeb版のみ作成・掲載しております。',
      isExternalLink: false,
    },
  ],
} as const

export const IR_INVESTOR_ROUTE = {
  path: '/ir/investor/',
  name: '個人投資家の皆様へ',
  imagePath: '/images/globalnavi-ir-Investor.svg',
  isExternalLink: false,
  subdirectories: [
    {
      name: 'トップメッセージ',
      path: '/company/topmessage/',
      imagePath: '/images/company-top-message-link.svg',
      isExternalLink: false,
      detail:
        '「リテールDXを通じて世の中を豊かな社会へ」代表取締役会長のメッセージを掲載しております。',
    },
    {
      name: 'トライアルグループの事業内容',
      path: '/business/',
      imagePath: '/images/ir-business-link.svg',
      detail:
        '日本全国でディスカウントストアを運営している流通小売事業、流通業界に変革をもたらすAIソリューションを開発・提供するリテールAI事業について掲載しております。',
      isExternalLink: false,
    },
    {
      name: 'トライアルグループのあゆみ',
      path: '/company/history/',
      imagePath: '/images/ir-history-link.svg',
      detail:
        '創業期から培ってきた「流通小売×リテールAI」における挑戦の歴史をご紹介しております。',
      isExternalLink: false,
    },
    {
      name: '事業計画及び成長可能性',
      path: '/ir/library/projection/',
      imagePath: '/images/ir-library-projection-link.svg',
      detail:
        '当社グループのビジネスモデルや、中長期的な成長を実現するための戦略、施策などについて掲載しております。',
      isExternalLink: false,
    },
    {
      name: '業績・財務情報',
      path: '/ir/financial/',
      imagePath: '/images/ir-financial-link.svg',
      detail:
        '月次売上高速報や、四半期ごとの決算概要、連結財務諸表、業績予想について掲載しております。',
      isExternalLink: false,
    },
    {
      name: '株主通信',
      path: '/ir/library/newsletter/',
      imagePath: '/images/news-blog.svg',
      detail:
        '環境配慮の観点から、株主通信はWeb版のみ作成・掲載しております。',
      isExternalLink: false,
    },
    {
      name: 'トライアルの株主になりませんか',
      path: '/ir/investor/shareholder/',
      imagePath: '/images/ir-investor-shareholder-link.svg',
      detail: '当社の経営に参加でき、また、配当金を受け取ることができます。',
      isExternalLink: false,
    },
    {
      name: 'IRメール配信',
      path: '/ir/mail/',
      imagePath: '/images/ir-mail-link.svg',
      detail:
        '当社の適時開示情報などIR情報について、ご登録のメールアドレスへ配信いたします。',
      isExternalLink: false,
    },
    {
      name: 'よくあるご質問',
      path: '/ir/faq/',
      imagePath: '/images/ir-faq-link.svg',
      detail: 'よくいただくご質問とその回答について掲載しております。',
      isExternalLink: false,
    },
  ],
} as const

export const IR_STOCK_ROUTE = {
  path: '/ir/stock/',
  name: '株式情報',
  imagePath: '/images/globalnavi-ir-stock.svg',
  isExternalLink: false,
  subdirectories: [
    {
      name: '株価情報',
      path: '/ir/stock/price/',
      imagePath: '/images/ir-stock-price.webp',
      detail: '株価チャートを掲載しております。',
      isExternalLink: false,
    },
    {
      name: '株式基本情報',
      path: '/ir/stock/info/',
      imagePath: '/images/ir-stock-info.webp',
      detail: '当社株式に関する基本情報について掲載しております。',
      isExternalLink: false,
    },
    {
      name: '株式の状況',
      path: '/ir/stock/situation/',
      imagePath: '/images/ir-stock-situation.webp',
      detail: '株式の発行数、株主数を掲載しております。',
      isExternalLink: false,
    },
    {
      name: '配当・株主還元',
      path: '/ir/stock/dividend/',
      imagePath: '/images/ir-stock-dividend.webp',
      detail:
        '利益配分に関する基本方針、配当金の情報について掲載しております。',
      isExternalLink: false,
    },
    {
      name: '株主総会',
      path: '/ir/stock/meeting/',
      imagePath: '/images/ir-stock-meeting.webp',
      detail: '株主総会の招集通知や決議通知等を掲載しております。',
      isExternalLink: false,
    },
    {
      name: '定款・株式取扱規程',
      path: '/ir/stock/articles/',
      imagePath: '/images/ir-stock-articles.webp',
      detail: '当社の定款及び株式取扱規程を掲載しております。',
      isExternalLink: false,
    },
    {
      name: 'アナリストカバレッジ',
      path: '/ir/stock/analyst-coverage/',
      imagePath: '/images/ir-library-projection-link.svg',
      detail: '当社を担当されているアナリストを掲載しております。',
      isExternalLink: false,
    },
  ],
} as const

export const IR_OTHER_ROUTE = {
  name: 'その他',
  isExternalLink: false,
  subdirectories: [
    {
      name: 'IRニュース',
      path: '/ir/news/',
      imagePath: '/images/globalnavi-ir-news.svg',
      isExternalLink: false,
    },
    { name: 'IRカレンダー', path: '/ir/calendar/', isExternalLink: false },
    { name: 'よくあるご質問', path: '/ir/faq/', isExternalLink: false },
    { name: 'IRお問い合わせ', path: '/contact/ir/', isExternalLink: false },
    {
      name: 'IRメール配信',
      path: '/ir/mail/',
      isExternalLink: false,
    },
    { name: '電子公告', path: '/ir/public-notice/', isExternalLink: false },
    { name: '免責事項', path: '/ir/disclaimer/', isExternalLink: false },
  ],
} as const

const IR_ROUTE = {
  path: '/ir/',
  name: '株主・投資家向け情報',
  subdirectories: [
    IR_OTHER_ROUTE.subdirectories[0],
    IR_MANAGE_ROUTE,
    IR_GOVERNANCE_ROUTE,
    IR_FINANCIAL_ROUTE,
    IR_LIBRARY_ROUTE,
    IR_INVESTOR_ROUTE,
    IR_STOCK_ROUTE,
  ],
} as const

export const IR_PICKUP_ROUTE = [
  IR_MANAGE_ROUTE,
  IR_GOVERNANCE_ROUTE,
  IR_FINANCIAL_ROUTE,
  IR_LIBRARY_ROUTE,
  IR_INVESTOR_ROUTE,
  IR_STOCK_ROUTE,
] as const

export const CAREERS_ROUTE = {
  path: '/careers/',
  name: '採用情報',
  subdirectories: [
    {
      path: 'https://www.trial-net.co.jp/recruit/newgraduate/',
      name: '新卒採用',
      imagePath: '/images/careers-newgraduate-links.svg',
      detail: '職種紹介を始め、社員インタビュー等をご覧いただけます。',
      isExternalLink: true,
    },
    {
      path: 'https://careers.trial-net.co.jp/',
      name: 'キャリア採用',
      imagePath: '/images/careers-trialgp-links.svg',
      detail: '現在募集中のポジション求人等をご覧いただけます。',
      isExternalLink: true,
    },
    {
      path: 'https://trial-recruit.net/jobfind-pc/',
      name: 'パート・アルバイト',
      imagePath: '/images/careers-jobfind-pc-links.svg',
      detail:
        'お住い近くのトライアルで、空いている時間を有効活用して働けます。',
      isExternalLink: true,
    },
    {
      path: 'https://recruit.jobcan.jp/retail-ai/',
      name: 'Retail AI',
      imagePath: '/images/careers-retail-ai-recruit-links.svg',
      detail:
        'Retail AIグループで現在募集中のポジション求人等をご覧いただけます。',
      isExternalLink: true,
    },
  ],
} as const

export const LEGAL_ROUTE = {
  path: '/legal/',
  name: '法務',
  subdirectories: [
    {
      name: 'プライバシーポリシー',
      path: '/legal/privacy-policy/',
      isExternalLink: false,
    },
    {
      name: '利用条件',
      path: '/legal/terms/',
      isExternalLink: false,
    },
    {
      name: 'SNSポリシー',
      path: '/legal/sns-policy/',
      isExternalLink: false,
    },
    {
      name: '情報セキュリティポリシー',
      path: '/legal/security-policy/',
      isExternalLink: false,
    },
    {
      name: '行動規範',
      path: '/legal/code/',
      isExternalLink: false,
    },
    {
      name: '画像規約',
      path: '/legal/image-terms/',
      isExternalLink: false,
    },
  ],
} as const

export const CONTACT_ROUTE = {
  path: '/contact/',
  name: 'お問い合わせ',
  subdirectories: [
    {
      path: '/contact/media/',
      imagePath: '/images/contact-link.webp',
      name: 'メディアの皆様',
      detail: '取材等に関するお問い合わせ',
      isExternalLink: false,
    },
    {
      path: '/contact/corporation/',
      imagePath: '/images/contact-link.webp',
      name: '法人のお客様',
      detail: '事業・サービスに関するお問い合わせ・協業のご提案',
      isExternalLink: false,
    },
    {
      path: '/contact/ir/',
      imagePath: '/images/contact-link.webp',
      name: '株主・投資家の皆様',
      detail: 'IR（株主・投資家向け情報）に関するお問い合わせ',
      isExternalLink: false,
    },
    {
      path: 'http://ted.trial-net.co.jp/',
      imagePath: '/images/contact-link.webp',
      name: '出店希望の皆様',
      detail: '出店希望に関するお問い合わせ',
      isExternalLink: true,
    },
    {
      path: 'https://www.trial-net.co.jp/contacts/',
      imagePath: '/images/contact-link.webp',
      name: '個人のお客様',
      detail: 'トライアル店舗に関するお問い合わせ',
      isExternalLink: true,
    },
    {
      path: 'https://su-pay.jp/contact/',
      imagePath: '/images/contact-link.webp',
      name: 'SU-PAY',
      detail: 'フィナンシャルサービスに関するお問い合わせ',
      isExternalLink: true,
    },
  ],
} as const

const SITE_MAP_ROUTE = {
  path: '/sitemap',
  name: 'その他',
  subdirectories: [
    {
      path: '/sitemap/',
      name: 'サイトマップ',
      isExternalLink: false,
    },
  ],
} as const

const PUBLIC_NOTICE_ROUTE = {
  path: '/public-notice',
  name: '電子公告',
  subdirectories: [
    {
      path: '/public-notice',
      name: '電子公告',
      isExternalLink: false,
    },
  ],
} as const

export const NAVIGATIONS = [
  COMPANY_ROUTE,
  BUSINESS_ROUTE,
  NEWS_ROUTE,
  IR_ROUTE,
  CAREERS_ROUTE,
] as const

export const FOOTER_BOTTOM_NAVIGATIONS = [
  { path: CONTACT_ROUTE.path, name: CONTACT_ROUTE.name },
  ...LEGAL_ROUTE.subdirectories,
  ...SITE_MAP_ROUTE.subdirectories,
  ...PUBLIC_NOTICE_ROUTE.subdirectories,
] as const

const CONTACT_SITE_MAP = {
  ...CONTACT_ROUTE,
  subdirectories: CONTACT_ROUTE.subdirectories.map((sub) => ({
    ...sub,
    name: sub.detail,
  })),
}

export const SITE_MAP_NAVIGATION = [
  COMPANY_ROUTE,
  BUSINESS_ROUTE,
  NEWS_ROUTE,
  IR_ROUTE,
  CONTACT_SITE_MAP,
  CAREERS_ROUTE,
  LEGAL_ROUTE,
  SITE_MAP_ROUTE,
  PUBLIC_NOTICE_ROUTE,
] as const
